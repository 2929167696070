import React from 'react'
import './404.scss'
import { Container } from '../PageContainer'
import { Button } from '@material-ui/core'
import { Link } from 'gatsby'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const unknownPage = ({ title, description }) => {
  return (
    <header style={{ position: 'relative' }}>
      <Container>
        <div className='hero'>
        <div className='hero__center'>
            <article className='hero__info'>
              <h1 className='hero__title'><ErrorOutlineIcon /> {title}</h1>
              <h4 className='hero__description'>{description}</h4>
              <div>
                  <Link to="/">
                    <Button
                    className="bookAppointment"
                    style={{ backgroundColor: '#19D5D3', color: 'white', border: 'none' }}
                    variant='outlined'
                    >
                    Back home
                    </Button>
                    </Link>
                </div>
            </article>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default unknownPage
