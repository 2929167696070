import Layout from 'components/Layout'
import React from 'react'
import AppStateProvider from '../state'
import UnknownPage from '../components/404'

function RootIndex() {
  return (
      <AppStateProvider>
        <Layout>
            <UnknownPage title={"404 - Page not found"} description={"The page you are trying to see does not exists."} />
        </Layout>
      </AppStateProvider>
  )
}

export default RootIndex
